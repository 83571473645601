var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-bar",on:{"click":_vm.onTopBar}},[_c('section',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.systemConfig.logo || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/1fd3bb636b7aafa6919a97eb1f1eabb0.png',"alt":"logo"}}),(_vm.systemConfig.sortName)?_c('p',{staticClass:"project-name"},[_vm._v(" "+_vm._s(_vm.systemConfig.sortName)+" ")]):_vm._e()]),(!_vm.isUpdatePwd)?_c('section',{staticClass:"top-right-box"},[(!_vm.hiddenAppBtn)?_c('div',{staticClass:"left-handle-cont"},[_c('ul',{staticClass:"nav-list"},_vm._l((_vm.topNav),function(item){return (
            !item.is_hide &&
            _vm.judgingArchiAPermission(
              item.archi_limit,
              item.permissions,
              true
            ) &&
            _vm.judgingCustomLogic(item.custom_logics)
          )?_c('li',{key:item.key,staticClass:"nav-item",class:{
            active:
              _vm.currentMenu &&
              (+_vm.currentMenu.id === +item.id ||
                _vm.currentMenu.menu_path === item.menu_path) &&
              !_vm.showapps,
          },on:{"click":function($event){return _vm.onTopNav(2, item, true)}}},[(item.icon)?[(item.icon.indexOf('el-icon') === 0)?_c('i',{class:['element-iconfont', item.icon]}):_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + item.icon}})])]:_vm._e(),_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.name))])],2):_vm._e()}),0)]):_vm._e(),_c('div',{staticClass:"right-handle-cont"},[_c('div',{staticClass:"quick-function-cont"},[(_vm.systemConfig && _vm.systemConfig.need_second)?_c('div',{staticClass:"item",on:{"click":function($event){_vm.drawer = true}}},[_c('i',{staticClass:"iconfont iconpage-search-16"}),_c('p',[_vm._v("搜索")])]):_vm._e(),(_vm.systemConfig && _vm.systemConfig.need_second && !_vm.hiddenAppBtn)?_c('div',{staticClass:"item",on:{"click":_vm.onHome}},[_c('i',{staticClass:"iconfont icona-yeqianshouyehome"}),_c('p',[_vm._v("首页")])]):_vm._e(),(_vm.systemConfig && _vm.systemConfig.need_app_center)?_c('div',{staticClass:"item",class:{ active: _vm.showapps },on:{"click":_vm.onAPPCenter}},[_c('i',{staticClass:"iconfont iconyingyong1"}),_c('p',[_vm._v("应用")])]):_vm._e(),_c('appQrcode',{attrs:{"top":"64"}})],1),(_vm.needFiveParties)?_c('Cooperation'):_vm._e(),(_vm.needArchiInfo)?_c('SelectArchi',{staticStyle:{"min-width":"70px","overflow":"hidden"},attrs:{"reload":true,"top":"78"}}):_vm._e(),_c('section',{staticClass:"infos"},[_c('UserActionsFour',{attrs:{"infoDescBoxStyle":{ padding: '19px 0px 19px 0px' }}})],1)],1)]):_vm._e(),_c('APPCenter',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAppCenter),expression:"showAppCenter"}],attrs:{"height":"calc(100% - 78px)"},on:{"getData":_vm.onUpdateAPPData}}),_c('el-drawer',{attrs:{"title":"全局搜索","visible":_vm.drawer,"direction":"rtl"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('SearchDrawer',{on:{"close-drawer":function($event){_vm.drawer = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }