<!--
 * @Description: 架构树
 * @Author: luocheng
 * @Date: 2021-11-03 14:41:58
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-13 16:40:52
-->
<template>
	<div class="archi-tree"
    :style="{
      paddingBottom: level === 0 ? '4px' : 0
    }"
  >
		<div
			v-for="item in renderData"
			:key="item.id"
		>
			<ArchiItem
				:archiData="item"
				:level="level + 1"
        @click.native="onToggle(item)"
        :style="{
          padding: '0 16px',
          paddingLeft: level > 0 ? (level + 1) * 16 + 'px' : '16px',
        }"
			></ArchiItem>
			<!-- 子架构 -->
      <template v-if="item.children && item.children.length">
        <ArchiTree
          :level="level + 1"
          :treeData="item.children"
          v-show="item.showChildren"
        ></ArchiTree>
      </template>
		</div>
	</div>
</template>

<script>
import ArchiItem from './ArchiItem';

export default {
	name: 'ArchiTree',
	props: {
		// 树数据
		treeData: {
			type: Array,
			default: () => [],
			required: true
		},
		// 层级
		level: {
			type: Number,
			default: 0,
			required: false
		},
    // 选中颜色
    activeColor: {
      type: String,
      default: '#d0e2ff',
      required: false
    }
	},
	components: {
		ArchiItem
	},
  computed: {
    // 用于渲染的数据
    renderData() {
      return this.treeData.map(ele => {
        return {
          ...ele,
          showChildren: this.level === 0
        }
      });
    }
  },
  methods: {
    /**
     * @description: 切换架构
     * @param {Object} data 应用架构
     * @return {Void}
     */
    onToggle(data) {
      this.$store.commit('setCacheArchi', data);
      if (!data || !data.children || !data.children.length) return;
      this.renderData.splice(this.renderData.findIndex(ele => ele.id === data.id), 1, {
        ...data,
        showChildren: !data.showChildren
      });
    }
  }
};
</script>

<style lang="less" scoped>
.archi-tree {
	width: 100%;
  box-sizing: border-box;
  transition: all .3s;
}
</style>