<!--
 * @Description: 入口文件顶部bar
 * @Author: luocheng
 * @Date: 2021-11-02 10:11:06
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-06-19 14:35:49
-->
<template>
  <div class="top-bar" @click="onTopBar">
    <section class="logo">
      <img
        :src="systemConfig.logo || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/1fd3bb636b7aafa6919a97eb1f1eabb0.png'"
        alt="logo"
      />
      <p class="project-name" v-if="systemConfig.sortName">
        {{ systemConfig.sortName }}
      </p>
    </section>
    <section class="top-right-box" v-if="!isUpdatePwd">
      <!-- 左侧应用切换区域 -->
      <div class="left-handle-cont" v-if="!hiddenAppBtn">
        <!-- 模块 -->
        <!-- eslint-disable -->
        <ul class="nav-list">
          <li
            class="nav-item"
            v-for="item in topNav"
            :key="item.key"
            :class="{
              active:
                currentMenu &&
                (+currentMenu.id === +item.id ||
                  currentMenu.menu_path === item.menu_path) &&
                !showapps,
            }"
            @click="onTopNav(2, item, true)"
            v-if="
              !item.is_hide &&
              judgingArchiAPermission(
                item.archi_limit,
                item.permissions,
                true
              ) &&
              judgingCustomLogic(item.custom_logics)
            "
          >
            <template v-if="item.icon">
              <i
                :class="['element-iconfont', item.icon]"
                v-if="item.icon.indexOf('el-icon') === 0"
              ></i>
              <svg aria-hidden="true" class="iconfont-svg" v-else>
                <use :xlink:href="'#' + item.icon" />
              </svg>
            </template>
            <span class="label">{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <!-- 选择架构 -->
      <div class="right-handle-cont">
        <!-- 返回首页 -->
        <div class="quick-function-cont">
          <div
            class="item"
            @click="drawer = true"
            v-if="systemConfig && systemConfig.need_second"
          >
            <i class="iconfont iconpage-search-16"></i>
            <p>搜索</p>
          </div>
          <div
            class="item"
            @click="onHome"
            v-if="systemConfig && systemConfig.need_second && !hiddenAppBtn"
          >
            <i class="iconfont icona-yeqianshouyehome"></i>
            <p>首页</p>
          </div>
          <div
            class="item"
            :class="{ active: showapps }"
            v-if="systemConfig && systemConfig.need_app_center"
            @click="onAPPCenter"
          >
            <i class="iconfont iconyingyong1"></i>
            <p>应用</p>
          </div>
          <!-- APP下载二维码 v-if="needAppQrcode" -->
          <appQrcode top="64"></appQrcode>
        </div>
        <!-- 五方 -->
        <Cooperation v-if="needFiveParties"></Cooperation>
        <!-- 架构 -->
        <SelectArchi
          style="min-width: 70px; overflow: hidden"
          :reload="true"
          v-if="needArchiInfo"
          top="78"
        ></SelectArchi>
        <!-- 个人信息 -->
        <section class="infos">
          <UserActionsFour
            :infoDescBoxStyle="{ padding: '19px 0px 19px 0px' }"
          />
        </section>
      </div>
    </section>
    <!-- 用户中心 -->
    <APPCenter
      v-show="showAppCenter"
      @getData="onUpdateAPPData"
      height="calc(100% - 78px)"
    ></APPCenter>
    <el-drawer title="全局搜索" :visible.sync="drawer" direction="rtl">
      <SearchDrawer @close-drawer="drawer = false" />
    </el-drawer>
  </div>
</template>

<script>
import cardMixin from "@/custom-component/business/cardMixin";
import SearchDrawer from "@/views/newSecond/components/index/SearchDrawer.vue"
import mixin from "./mixin";

export default {
  name: "TopBar",
  mixins: [cardMixin, mixin],
  components: {
    SearchDrawer,
  },
  data() {
    return {
      drawer: false,
    };
  },
  props: {
    hiddenAppBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

};
</script>

<style lang="less" scoped>
@height: 78px;
.top-bar {
  display: flex;
  height: @height!important;
  box-sizing: border-box;
  background: linear-gradient(
    90deg,
    rgba(247, 171, 25, 0) 39.76%,
    rgba(242, 119, 19, 0.05) 74.55%,
    rgba(231, 0, 6, 0.05) 99.4%
  );
  user-select: none;
  background-size: cover;
  .logo {
    display: flex;
    // width: 408px;
    padding: 8px 0px;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 24px;
    min-width: 230px;
    img {
      width: 100%;
      height: 100%;
      margin: auto;
      margin-left: 0;
    }
    .project-name {
      color: var(--text-on-surface-secondary, #424751);
      font-family: "PingFang SC";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.5px;
      white-space: nowrap;
      position: relative;
      height: 32px;
      line-height: 32px;
      padding: 15px 0 15px 17px;
    }
    .project-name::before {
      content: "";
      width: 3px;
      height: 3px;
      background-color: #424751;
      border-radius: 50%;
      position: absolute;
      left: 3px;
      top: 30px;
    }
  }
  .top-right-box {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .left-handle-cont {
      min-height: @height!important;
      display: flex;
      .manage-center {
        box-sizing: border-box;
        padding: 12px 16px;
        display: flex;
        cursor: pointer;
        img {
          margin-top: 1px;
          height: 22px;
          width: 22px;
          margin-right: 8px;
        }
        .label {
          line-height: 24px;
          box-sizing: border-box;
          padding: 0 15px;
          color: #fff;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .iconfont {
          font-size: 16px;
          line-height: 24px;
          color: #fff;
        }
      }
      .nav-list {
        flex: 1;
        display: flex;
        height: 32px;
        line-height: 32px;
        overflow: hidden;
        padding: 23px 0;
        overflow-x: auto;
        .nav-item {
          color: #7a8599;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0.5px;
          border-radius: var(--radius-circle, 256px);
          background: #e6e6e6;
          margin-left: 24px;
          width: auto;
          padding: 0 12px;
          overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          &.active {
            color: var(--Color-01, #e46445);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.5px;
            background: #ffe6e0;
          }
          .iconfont-svg {
            height: 100%;
            width: 16px;
            height: 26px;
            position: relative;
            top: 4px;
            background-color: transparent;
            margin-right: 5px;
            font-size: 16px;
            line-height: 16px;
            padding-right: 5px;
            color: #fff;
          }
          .element-iconfont {
            height: 30px;
            line-height: 30px;
            padding-right: 5px;
          }
        }
      }
    }
    .right-handle-cont {
      height: @height!important;
      display: flex;
      padding-right: 32px;
      box-sizing: border-box;
      :deep(.qrcode-box) {
        left: -88px !important;
      }
      .select-archi {
        height: 100%;
      }
      .quick-function-cont {
        display: flex;
        flex-direction: row;
        height: 48px;
        padding: 15px 0;
        text-align: center;
        .item {
          color: #707786;
          width: 40px;
          height: 40px;
          padding: 4px;
          margin-left: 4px;
          i {
            font-size: 20px;
          }
          p {
            color: var(--text-on-surface-tertiary, #707786);
            font-family: "PingFang SC";
            height: 18px;
            line-height: 18px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.4px;
          }
          &.active {
            border-radius: var(--radius-4, 8px);
            background: rgba(228, 100, 69, 0.2);
          }
          &:hover {
            border-radius: var(--radius-4, 8px);
            background: rgba(228, 100, 69, 0.2);
          }
        }
        ::v-deep(.app-qrcode-icon) {
          &:hover {
            background: none;
          }
          .icon-cont {
            &:hover {
              border-radius: var(--radius-4, 8px);
              background: rgba(228, 100, 69, 0.2);
            }
          }
        }
      }
      .infos {
        display: flex;
        box-sizing: border-box;
        .info-item {
          height: 100%;
          width: @height;
          box-sizing: border-box;
          line-height: 24px;
          font-size: 16px;
          color: #fff;
          box-sizing: border-box;
          padding: 12px 0;
          text-align: center;
          cursor: pointer;
          &:hover {
            color: #fff;
            background: rgb(77, 134, 232);
            opacity: 1;
          }
          &.active {
            color: #fff;
            opacity: 1;
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1800px) {
  .project-name {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .quick-function-cont {
    display: none !important;
  }
}
@media screen and (max-width: 800px) {
  .left-handle-cont,
  .select-archi {
    display: none !important;
  }
}
::v-deep(.el-drawer) {
  .el-drawer__header {
    padding: 16px 12px 16px 20px;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid var(--border-on-surface-weak, #f0f1f4);
  }
  width: 500px !important;
  .el-drawer__body {
    padding: 20px;
  }
}
</style>
