var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"search"},[_c('el-autocomplete',{ref:"el_auto",attrs:{"popper-class":_vm.noData ? 'platform-auto-complete' : '',"fetch-suggestions":_vm.querySearch,"placeholder":"搜索","prefix-icon":"el-icon-search","popper-append-to-body":false,"size":"medium"},on:{"select":_vm.handleSelect,"clear":_vm.clearSelect,"focus":_vm.handleFocus},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return (
          _vm.judgingArchiAPermission(item.archi_limit, item.permissions) &&
          _vm.judgingUILogic(item.UILogic, _vm.componentList || _vm.componentData)
        )?[(!_vm.noData)?_c('div',{staticClass:"content-item"},[(item.shortcut_icon)?_c('div',{staticClass:"content-icon"},[(!item.shortcut_icon)?_c('i',{staticClass:"iconfont iconzhuomianyingyong"}):_vm._e(),(item.shortcut_icon.indexOf('el-icon-') === 0)?_c('i',{class:[item.shortcut_icon]}):_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + item.shortcut_icon}})])]):_c('div',{staticClass:"content-icon"},[(
                item.icon && item.icon.toString().indexOf('el-icon') === 0
              )?_c('i',{class:['item-icon', item.icon]}):_c('i',{class:['item-icon', 'iconfont', item.icon || 'iconyeqian']})]),_c('div',{staticClass:"content-text"},[_c('p',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item.shortcut_name ? item.shortcut_name : item.name)+" ")])])]):_c('div',{staticClass:"default"},[_vm._v(" "+_vm._s(item.default)+" ")])]:undefined}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_c('div',{staticClass:"btn",on:{"click":_vm.clearSelect}},[_vm._v("搜索")])],1),_c('div',{staticClass:"history-list"},[_c('div',{staticClass:"title"},[_vm._v("最近搜索")]),_c('div',{staticClass:"content"},_vm._l((_vm.menuListData.slice(0, 7)),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.handleSelect(item)}}},[_c('div',{staticClass:"icon"},[(item.shortcut_icon)?_c('div',{staticClass:"content-icon"},[(!item.shortcut_icon)?_c('i',{staticClass:"iconfont iconzhuomianyingyong"}):_vm._e(),(item.shortcut_icon.indexOf('el-icon-') === 0)?_c('i',{class:[item.shortcut_icon]}):_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + item.shortcut_icon}})])]):_c('div',{staticClass:"content-icon"},[(
                item.icon && item.icon.toString().indexOf('el-icon') === 0
              )?_c('i',{class:['item-icon', item.icon]}):_c('i',{class:['item-icon', 'iconfont', item.icon || 'iconyeqian']})])]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(item.shortcut_name ? item.shortcut_name : item.name)+" ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }