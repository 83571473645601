<!--
 * @Description: 最近查看
 * @Author: luocheng
 * @Date: 2021-11-05 14:58:33
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-21 09:15:22
-->
<template>
	<div
		class="recent select-archi-node 11"
	>
		<SearchBox v-model="filterName"></SearchBox>
		<article class="recent-main select-archi-node">
			<div class="recent-child archi-list select-archi-node">
				<section class="compny item select-archi-node">
					<GroupItem
						:title="'企业'"
						:styleObj="styleObj"
						:dataList="companysForShow"
						:current="targetId"
					></GroupItem>
				</section>
				<section class="project item select-archi-node">
					<GroupItem
						:title="'项目'"
						:styleObj="styleObj"
						:dataList="projectForShow"
						:current="targetId"
					></GroupItem>
				</section>
			</div>
			<div class="recent-child archi-infos select-archi-node">
				<ArchiInfo :reload="reload"></ArchiInfo>
			</div>
		</article>
	</div>
</template>

<script>
import SearchBox from '../SearchBox';
import GroupItem from '../GroupItem';
import ArchiInfo from '../ArchiInfo';
import { mapState } from 'vuex';

export default {
	name: 'RecentView',
	components: {
		SearchBox,
		GroupItem,
		ArchiInfo
	},
  props: {
    // 最近登录 
    historyArchi: {
      type: Object,
      required: true,
      default: () => {}
    },
		reload: {
			type: Boolean,
			required: false,
			default: false
		}
  },
	data() {
		return {
			styleObj: {
				height: '200px'
			},
			filterName: '',
      historyProjects: [],
      historyCompanys: [],
			// 当前选中
			targetId: null,
			companysForShow: [],
			projectForShow: []
		};
	},
	computed: {
		...mapState([
			'cacheArchi',
			'targetArchi'
		]),
		// 架构信息
		archiInfo() {
			return this.cacheArchi || this.targetArchi;
		}
	},
  created() {
    const historyProjects = this.historyArchi.projects || [];
		this.historyProjects = historyProjects.map(ele => {
			return {
				...ele,
				id: ele.archi_type || ele.id
			}
		})
    const historyCompanys = this.historyArchi.companys || [];
		this.historyCompanys = historyCompanys.map(ele => {
			return {
				...ele,
				id: ele.archi_type || ele.id
			}
		})
		this.targetId = this.$GetTargetArchi("id");
		this.companysForShow = this.historyCompanys;
		this.projectForShow = this.historyProjects;
		this.defaultSelect();
  },
	watch: {
		filterName() {
			this.companysForShow = this.historyCompanys.filter(ele => {
				const name = ele.name || ele.short_name || '';
				return name.includes(this.filterName || '');
			});
			this.projectForShow = this.historyProjects.filter(ele => {
				const name = ele.name || ele.short_name || '';
				return name.includes(this.filterName || '');
			});
			this.defaultSelect();
		}
	},
	methods: {
		/**
		 * @description: 处理默认选中
		 */
		defaultSelect() {
			// 将最右侧改为默认选中一个，逻辑为有当前项目则选中当前项目，没有则默认选中一个 @boss@晓雯 2023.4.6
			if (this.projectForShow.find(ele => ele.id === this.archiInfo.id) 
				|| this.companysForShow.find(ele => ele.id === this.archiInfo.id)
			) {
				return;
			}
			// 按企业 项目顺序进行默认选中
			if (this.companysForShow.length) {
				this.$store.commit('setCacheArchi', this.companysForShow[0]);
			} else {
				this.$store.commit('setCacheArchi', this.projectForShow[0] || null);
			}
		}
	}
};
</script>

<style lang="less" scoped>
@itemWidth: 252px;
.recent {
	display: flex;
	flex-direction: column;
	.recent-main {
		flex: 1;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding-top: 16px;
		.recent-child {
			width: @itemWidth;
			height: 100%;
			overflow: hidden;
			&.archi-list {
				.project {
					margin-top: 16px;
				}
			}
		}
	}
}
</style>