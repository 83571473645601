<!--
 * @Author: zqy
 * @Date: 2023-06-30 10:57:43
 * @LastEditTime: 2024-05-21 18:24:45
 * @Description: 
 * @LastEditors: 冉桂精 156189868@qq.com
-->
<template>
  <div>
    <div class="app-qrcode-icon" @click="active=!active">
      <div class="icon-cont" v-if="+systemConfig.theme_type === 4">
        <i class="iconfont icona-APPxiazai"></i>
				<p>下载</p>
      </div>
      <i v-else class="iconfont iconyidong"></i>
      <div class="qrcode-box" :style="{ height: active? '160px;' : '160px;', width:needCameraQrcode?'320px':'160px',left:needCameraQrcode?'-200px':'-40px',top:top+'px'}" style='width:320px'>
        <div class='qrcode-content'>
          <p class="info">扫码下载APP</p>
          <img :src="qrCode" alt="" width="100" height="100">
          <!-- <p class="info">请使用系统自带扫码功能</p> -->
        </div>
        <div class='qrcode-content' v-if='needCameraQrcode'>
          <p class="info">扫码下载水印相机</p>
          <img :src="camareCode" alt="" width="100" height="100">
          <!-- <p class="info">请使用系统自带扫码功能</p> -->
        </div>
      </div>
    </div>
    <!-- mask -->
    <div class="actions-mask" v-show="active" @click.self="changeActive"></div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapState } from "vuex";
export default {
  props: {
    top: {
      type:[Number,String],
      default: 45
    }
  },
  computed: {
    ...mapState([
      "systemConfig",
    ]),
    needCameraQrcode() {
      return !!this.systemConfig?.camera_downLoad_Qrcode;
    },
  },
  data(){
    return{
      qrCode: null,
      camareCode: null,
      active: false,
    }
  },
  methods:{
    changeActive() {
      console.log('*****************');
      this.active = !this.active;
      console.log('this.active:',this.active);
    }
  },  
  mounted(){
    // 生成二维码
    const params = {
      qrcode_data: `${window.location.origin}/h5/appDownload`,
      margin: 1,
      logo_path: ``
    }
    axios({
        params: params,
        url: `${process.env.VUE_APP_BASE_URL}api/v4/qrcode/info/url`,
        method: 'get',
        responseType: 'blob'
      }).then(res=>{
      console.log('res:',res);
      let blob = res.data;
      let reader = new FileReader();
      reader.readAsDataURL(blob);  // 转换为base64
      reader.onload = () => {
        this.qrCode= reader.result
      }
    })
    if(this.needCameraQrcode){
      const params1 = {
      qrcode_data: `${window.location.origin}/h5/cameraDownload`,
      margin: 1,
      logo_path: ``
    }
    axios({
        params: params1,
        url: `${process.env.VUE_APP_BASE_URL}api/v4/qrcode/info/url`,
        method: 'get',
        responseType: 'blob'
      }).then(res=>{
      console.log('res:',res);
      let blob = res.data;
      let reader = new FileReader();
      reader.readAsDataURL(blob);  // 转换为base64
      reader.onload = () => {
        this.camareCode= reader.result
      }
    })
    }

  }
}
</script>
<style lang="less" scoped>
.app-qrcode-icon{
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .icon-cont{
    color: #707786;
    width: 40px;
    height: 40px;
    padding: 4px;
    margin-left: 4px;
    i{
      font-size: 20px;
      color: #707786;
    }
    p{
      color: var(--text-on-surface-tertiary, #707786);
      font-family: "PingFang SC";
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
    &.active {
      border-radius: var(--radius-4, 8px);				
      background: rgba(228, 100, 69, 0.05);
    }
    &:hover{
      border-radius: var(--radius-4, 8px);				
      background: rgba(228, 100, 69, 0.05);
    }
  }
  .qrcode-box{
    display:flex;
    justify-content: space-around;
    top: 45px;
    left: -40px;
    position: absolute;
    z-index: 15;
    width: 160px;
    height: 0;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px -1px rgb(0 0 0 / 8%);
    transition: all 0.2s;
    overflow: hidden;
    background: #fff;
    z-index:30003;
    .qrcode-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .info{
      padding: 10px 0;
      font-size: 12px;
      color:#6D768F;
    }
    }

  }
  &:hover{
    background: rgba(255, 255, 255, 0.1);
    .qrcode-box{
      height: 160px !important;
    }
  } 
  i{
    font-size: 26px;
    color: #fff;
  }
}
.actions-mask{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 2998;
  cursor: pointer;
}
</style>