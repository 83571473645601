<!--
 * @Description: 项目视图
 * @Author: luocheng
 * @Date: 2021-11-05 16:31:31
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-06-07 16:34:43
-->
<template>
  <div class="project-view" v-loading="loading">
    <ProjectHeader
      :companyList="companyList"
      @search="onFilter"
    ></ProjectHeader>
    <article class="project-main">
      <el-scrollbar style="height: 100%; overflow-x: hidden">
        <section class="project-archi-main">
          <template v-if="showContent && renderArchiData && renderArchiData.length">
            <GroupSection
              v-for="item in renderArchiData"
              :key="item.id"
              :groupData="item"
            ></GroupSection>
          </template>
          <div class="no-data" v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </section>
      </el-scrollbar>
      <section class="project-archi-info">
        <ArchiInfo :reload="reload"></ArchiInfo>
      </section>
    </article>
  </div>
</template>

<script>
import ProjectHeader from "./Header";
import { Empty, Scrollbar } from "element-ui";
import ArchiInfo from "../ArchiInfo";
import GroupSection from "./GroupSection";
import { dataInterface } from "@/apis/data/index";
import { mapState } from "vuex";
import { filterTreeData } from '@/utils/tools';

export default {
  name: "ProjectView",
  components: {
    ProjectHeader,
    ArchiInfo,
    GroupSection,
    "el-scrollbar": Scrollbar,
    "el-empty": Empty,
  },
  props: {
    reload: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // 公司列表
      companyList: [],
      // 架构数据
      archiData: [],
      // 用于渲染的数据
      renderArchiData: [],
      loading: false,
      showContent: true,
    };
  },
  created() {
    this.getAllArchiTree();
  },
  computed: {
    ...mapState(["cacheArchi", "targetArchi"]),
    // 架构信息
    archiInfo() {
      return this.cacheArchi || this.targetArchi;
    },
  },
  watch: {
    // 将最右侧改为默认选中一个，逻辑为有当前项目则选中当前项目，没有则默认选中一个 @boss@晓雯 2023.4.6
    renderArchiData: {
      handler() {
        if (!this.renderArchiData.length) return;
        let list = [];
        for (let i = 0; i < this.renderArchiData.length; i++) {
          if (i === 0) {
            if (this.renderArchiData[0].children.find((ele) => ele.id === this.archiInfo.id)) return;
          }
          list = list.concat(this.renderArchiData[i].children || []);
        }
        this.$store.commit("setCacheArchi", list[0] || null);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /**
     * @desc: 获取组织架构树
     */
    getAllArchiTree() {
      this.loading = true;
      dataInterface({
        __method_name__: "globalFunctionCall",
        typeName: "Archi",
        type: "value",
        funcName: "AllArchiTree",
      })
        .then((res) => {
          if (!res || res.status !== 200) return false;
          const { project = [], company_name = [] } = res.data.data;
          localStorage.setItem("myProjectsTree", JSON.stringify(project));
          this.allData = company_name.map((name) => {
            return {
              company_name: name,
              children: project.filter((item) => item.company_name === name),
            };
          });
          this.renderArchiData = this.allData.map(ele => {
						return {
							...ele,
							children: this.formatData(ele.children)
						}
					});
          this.archiData = JSON.parse(JSON.stringify(project));
          this.companyList = company_name.map((ele) => {
            return {
              label: ele,
              value: ele,
            };
          });
          this.companyList.unshift({
            label: "全部项目",
            value: "",
          });
          // this.companyList.unshift({
          // 	label: '测试项目',
          // 	value: '测试项目'
          // });
          // console.log(this.companyList, 'companyListcompanyList');
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    /**
     * @description: 格式化数据
     * @param {Array} list 数据列表
     * @return {Array} 结果
     */
		formatData(list) {
      const result = []; // 存放结果集
      const itemMap = {}; //
      for (const item of list) {
        const id = item.data_id;
        const parentId = item.parent_id;
        if (!itemMap[id]) {
          itemMap[id] = {
            children: [],
          };
        }
        itemMap[id] = {
          ...item,
          children: itemMap[id]["children"],
        };
        const treeItem = itemMap[id];
        if (+parentId === 0) {
          result.push(treeItem);
        } else {
          if (!itemMap[parentId]) {
            itemMap[parentId] = {
              children: [],
            };
          }
          itemMap[parentId].children.push(treeItem);
        }
      }
      // 处理无上级任职，当前类表中不存在parent_id为0的项目
      if (!result.length) {
        return list;
      }
      return result;
    },
    /**
     * @description: 筛选
     * @param {Object} data 筛选数据
     */
    onFilter(data) {
      this.showContent = false;
      const { companyName, keyWord } = data;
      let archiData = JSON.parse(JSON.stringify(this.allData));
      if (!Array.isArray(archiData)) return;
      let result = [];
      // 筛选公司
      if (companyName) {
        result = archiData.filter(
          (ele) => ele.company_name && ele.company_name === companyName
        );
      } else {
        result = archiData;
      }
      // 筛选项目
      // if (keyWord) {
      // 	result = this.getProjectByKeyWord(keyWord, archiData);
      // } else {
      // 	result = archiData;
      // }
      // 仅过滤以及项目
      if (!keyWord) {
        this.renderArchiData = result.map(ele => {
					return {
						...ele,
						children: this.formatData(ele.children)
					}
				});
        this.showContent = true;
        return;
      }

      // const filterResult = [];
			const filterResult = filterTreeData(result.map(ele => {
					return {
						...ele,
						children: this.formatData(ele.children)
					}
				}), keyWord);
      // for (let i = 0; i < result.length; i++) {
			// 	// 老的逻辑
      //   const item = result[i];
      //   if (Array.isArray(item?.children)) {
      //     window.testList = item.children;
      //     const children = item.children.filter((ele) =>
      //       ele.short_name.includes(keyWord)
      //     );
      //     if (children.length) {
      //       filterResult.push({
      //         ...item,
      //         children,
      //       });
      //     }
      //   }
      // }
			this.renderArchiData = filterResult;
      // this.renderArchiData = filterResult.map(ele => {
			// 	return {
			// 		...ele,
			// 		children: this.formatData(ele.children)
			// 	}
			// });
      this.showContent = true;
    },
    /**
     * @desc: 根据关键字过滤，包括后代节点（暂未启用）
     * @param {String} keyWord 字符
     * @param {Array} arr 被过滤数组
     * @param {Array} result 结果扁平化结果数组
     */
    getProjectByKeyWord(keyWord, arr) {
      let result = [];
      if (!keyWord || !arr || !arr.length) return result;
      arr.map((ele) => {
        if (ele.short_name.includes(keyWord)) {
          result.push(ele);
        }
        if (
          ele.children &&
          Array.isArray(ele.children) &&
          ele.children.length
        ) {
          result = result.concat(
            this.getProjectByKeyWord(keyWord, ele.children)
          );
        }
      });
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
@itemWidth: 252px;
.project-view {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .project-main {
    flex: 1;
    width: 100%;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 16px;
    justify-content: space-between;
    .project-archi-main {
      width: 263px;
      height: 100%;
      box-sizing: border-box;
      padding-left: -15px;
      overflow: hidden;
      margin-bottom: 12px;
      .no-data {
        height: 416px;
        width: @itemWidth;
        background: #fff;
        position: relative;
        border-radius: 4px;
        .el-empty {
          padding: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

:deep(.el-scrollbar__wrap) {
  overflow-x: hidden;
}
</style>
