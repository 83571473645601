<!--
 * @Description: 应用中心
 * @Author: luocheng
 * @Date: 2022-06-29 15:27:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-20 18:05:32
-->
<template>
  <div class="app-center" v-if="componentData" :style="{top:systemConfig?.theme_type === 4?'78px':'48px'}">
    <PageParser :customComponentData="componentData"></PageParser>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import PageParser from './Parser';
import { mapState } from 'vuex';
import { isJSONStr } from '@/utils/tools';

export default {
  name: 'APPCenter',
  components: {
    PageParser
  },
  computed: {
		...mapState(['systemConfig']),
  }, 
  props: {
    // 附加页面的UUID
    pageUUID: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      componentData: null
    }
  },
  created() {
    this.getViewData();
  },
  provide() {
    return {
      EDITOR_pageUUID: this.pageUUID
    }
  },
  methods: {
    /**
		 * @desc: 获取dataview配置数据
		 */
		getViewData() {
			this.loading = true;
			dataInterface({
				__method_name__: 'dataList',
				page_uuid: 'page62bbbaaa996ea',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			}).then((res) => {
        this.loading = false;
        const { page_data } = res?.data?.data?.[0] || {};
        const { componentData } = isJSONStr(page_data) ? JSON.parse(page_data) : page_data
        this.componentData = componentData;
        this.$emit('getData', componentData);
      }).catch(() => {
        this.loading = false;
      });
		}
  }
}
</script>

<style lang="less" scoped>
@top: 48px;
.app-center{
  width: 100vw;
  height: calc(100vh - @top);
  position: fixed;
  left: 0;
  top: @top;
  z-index: 30001;
  background: #fff;
}
</style>