<!--
 * @Description: 分组
 * @Author: luocheng
 * @Date: 2021-11-05 16:49:59
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-15 15:10:52
-->
<template>
  <div class="group-section">
    <h4 class="title">{{ groupData.company_name }}</h4>
    <ArchiTree :treeData="archiData"></ArchiTree>
  </div>
</template>

<script>
import ArchiTree from "./ArchiTree";

export default {
  name: "GroupSection",
  props: {
    groupData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      archiData: []
    }
  },
  components: {
    ArchiTree,
  },
  watch: {
    groupData: {
      handler() {
        this.archiData = this.groupData?.children || [];
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
@width: 252px;
.group-section {
  width: @width;
  margin-bottom: 12px;
  height: auto;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  &:last-of-type {
    margin: 0;
  }
  .title {
    box-sizing: border-box;
    padding: 6px 16px;
    width: 100%;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #445e96;
    line-height: 20px;
  }
}
</style>
